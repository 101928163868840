:root {
    @media (prefers-color-scheme: light) {
        --text-color: #202020;
        --bg-color: whitesmoke;
        --link-color: blue;
    }
    @media (prefers-color-scheme: dark) {
        --text-color: whitesmoke;
        --bg-color: #202020;
        --link-color: skyblue;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: "游ゴシック", "Yu Gothic", "メイリオ", "Meiryo";
    color: var(--text-color);
    font-size: 16px;
    transform-origin: 0% 0%;
    background-color: var(--bg-color);
}

.p-1 {
    padding: 1rem;
}

header {
    padding: 0 1rem;
    height: 60px;
    display: flex;
    align-items: center;
}

.brand {
    text-decoration: none;
    color: var(--text-color);
    font-weight: bold;
    font-size: 0.75rem;
}

main {
    max-width: 1000px;
    margin: auto;
    padding-top: 5rem;
}

section {
    padding: 1rem;
    margin-bottom: 1rem;
    h1 {
        margin-bottom: 2rem;
    }
}

.skills > section {
    border-top: 0.5px solid var(--text-color);
    border-bottom: 0.5px solid var(--text-color);
    margin-bottom: 2rem;
    padding: 1rem 0;
    h2 {
        font-weight: 100;
        margin-bottom: 1rem;
    }
}

a {
    color: var(--link-color);
    :visited {
        color: var(--link-color);
    }
}

.contact a {
    display: inline-block;
    line-height: 48px;
}
