@media (prefers-color-scheme: light) {
  :root {
    --text-color: #202020;
    --bg-color: whitesmoke;
    --link-color: blue;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-color: whitesmoke;
    --bg-color: #202020;
    --link-color: skyblue;
  }
}

body {
  color: var(--text-color);
  transform-origin: 0 0;
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
  font-family: 游ゴシック, Yu Gothic, メイリオ, Meiryo;
  font-size: 16px;
}

.p-1 {
  padding: 1rem;
}

header {
  height: 60px;
  align-items: center;
  padding: 0 1rem;
  display: flex;
}

.brand {
  color: var(--text-color);
  font-size: .75rem;
  font-weight: bold;
  text-decoration: none;
}

main {
  max-width: 1000px;
  margin: auto;
  padding-top: 5rem;
}

section {
  margin-bottom: 1rem;
  padding: 1rem;
}

section h1 {
  margin-bottom: 2rem;
}

.skills > section {
  border-top: .5px solid var(--text-color);
  border-bottom: .5px solid var(--text-color);
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.skills > section h2 {
  margin-bottom: 1rem;
  font-weight: 100;
}

a, a :visited {
  color: var(--link-color);
}

.contact a {
  line-height: 48px;
  display: inline-block;
}

/*# sourceMappingURL=index.1bc3dfce.css.map */
